<template>
  <div class="actions-wrapper">
    <el-dropdown
      class="d-flex justify-content-center"
      trigger="click"
      placement="bottom"
      @command="handleAction($event)"
    >
      <Button
        type="icon"
        class="el-button border-0 p-1 text-typography-primary el-button--text el-button--small el-dropdown-selfdefine actions-btn"
        @click.stop
      >
        <KebabIcon />
      </Button>
      <el-dropdown-menu>
        <el-dropdown-item v-for="item in items" :key="item.title" :command="item.clickhandler">
          <div class="d-flex gap-2 align-items-center">
            <component :is="item.icon"></component>
            <p>{{ item.title }}</p>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { ref } from 'vue';

import { KebabIcon, PartialIcon, CompleteIcon } from '@/assets/icons';

export default {
  components: {
    KebabIcon,
    PartialIcon,
    CompleteIcon,
  },
  props: {
    items: { type: Array, default: () => [] },
    data: { type: Object, default: () => {} },
  },
  setup(props) {
    const showActions = ref(false);
    const handleKebabClick = () => {
      showActions.value = !showActions.value;
    };

    const handleAction = (command) => command(props.data);

    return {
      showActions,
      handleKebabClick,
      handleAction,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.actions-wrapper {
  display: flex;
  justify-content: flex-end;
}
.actions-btn {
  background-color: transparent;
  border: 0px;
  border-radius: 3px;

  &:hover {
    background-color: #ecf0f3;
  }
}
</style>
