<template>
  <table
    class="table"
    :class="{
      [customClass]: customClass,
      ['table-hover']: hover && !openFilter && data.length !== 0 && !disabled,
      ['fixed-layout']: hasWidth,
      ['rounded-table']: rounded,
      ['table-sm']: small,
      ['disabled']: disabled,
    }"
  >
    <thead>
      <tr>
        <th
          v-if="rowSelection"
          scope="col"
          class="header-rowSelection"
          :class="{ ['sticky-column']: hasWidth && stickyColumns }"
          :style="{
            ...getAdditionalColumnStickyBounds('rowSelection'),
            width: `${additionalColumns.find((col) => col.name == 'rowSelection').width}px`,
          }"
        >
          <el-checkbox
            v-if="selectAllToggle"
            :value="checkAll"
            :disabled="isAllCheckboxesDisabled"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
          />
        </th>
        <th
          v-if="expandable"
          scope="col"
          class="header-expandable"
          :class="{ ['sticky-column']: hasWidth && stickyColumns }"
          :style="{
            ...getAdditionalColumnStickyBounds('expandable'),
            width: `${additionalColumns.find((col) => col.name == 'expandable').width}px`,
          }"
        />
        <th
          v-if="showIndex"
          scope="col"
          class="header-index"
          :class="{ ['sticky-column']: hasWidth && stickyColumns }"
          :style="{
            ...getAdditionalColumnStickyBounds('index'),
            width: `${additionalColumns.find((col) => col.name == 'index').width}px`,
          }"
        >
          <!-- @slot Override the default header of the index (only shown when showIndex is true) -->
          <slot name="header-index">
            <p>#</p>
          </slot>
        </th>
        <th
          v-for="(column, columnIndex) in columns"
          :key="`${columnIndex}-${column.key}`"
          :class="{
            ['sortable-header']: !!column.sortCallback,
            ['sticky-column']: isSticky(columnIndex),
            ['actionable-header']:
              !!column.sortCallback ||
              !!$scopedSlots[`filter-${column.key}`] ||
              !!$scopedSlots[`actions-${column.key}`],
            [column.customClass]: column.customClass,
            ['active-actions']: activeActions === columnIndex,
            ['hidden-column']: column.hidden,
          }"
          :style="getHeaderStyle(columnIndex)"
          scope="col"
        >
          <div class="d-flex gap-1 justify-content-between">
            <div class="d-flex gap-1">
              <div class="header-text">
                <!-- @slot Override the default header of the any column using column key -->
                <slot :name="`header-${column.key}`" v-bind="{ columnIndex, column }">
                  <p class="fw-bold d-inline">{{ column.header }}</p>
                </slot>
              </div>
              <Button
                v-if="!!column.sortCallback"
                class="sort-icon"
                type="icon"
                :class="{ ['active-sort']: sortState.columnKey === column.key }"
                @click="handleSortClick(column)"
              >
                <template v-if="sortState.direction === 1">
                  <ArrowUpIcon />
                </template>
                <template v-if="sortState.direction === -1">
                  <ArrowDownIcon />
                </template>
              </Button>
            </div>
            <div class="d-flex">
              <el-popover
                v-if="$scopedSlots[`filter-${column.key}`]"
                placement="bottom"
                trigger="click"
                :visible-arrow="false"
                :value="openFilter === columnIndex"
                @show="openFilter = columnIndex"
                @hide="openFilter = null"
              >
                <Button slot="reference" type="icon">
                  <FilterIcon :class="column.filterActive ? 'text-primary' : ''" />
                </Button>
                <!-- @slot What to display within the filter once the filter is clicked -->
                <slot :name="`filter-${column.key}`" />
              </el-popover>
              <el-dropdown
                v-if="$scopedSlots[`actions-${column.key}`]"
                trigger="click"
                placement="bottom"
                @command="$emit('header-action', { command: $event, columnIndex })"
                @visible-change="actionsVisibleChange($event, columnIndex)"
              >
                <Button :id="`actions-header-${columnIndex}`" type="icon" @click.stop="openFilter = null">
                  <KebabIcon />
                </Button>
                <!-- @slot What to display within the actions dropdown -->
                <slot :name="`actions-${column.key}`" />
              </el-dropdown>
            </div>
          </div>
        </th>
      </tr>
      <!-- @slot Implement sub header - no default -->
      <slot
        v-if="subHeader"
        :name="'sub-header'"
        :showIndex="showIndex"
        :hasWidth="hasWidth"
        :stickyColumns="stickyColumns"
        :additionalColumns="additionalColumns"
        :columns="columns"
        :getAdditionalColumnStickyBounds="getAdditionalColumnStickyBounds"
        :isSticky="isSticky"
        :getHeaderStyle="getHeaderStyle"
      />
    </thead>
    <tbody>
      <tr v-if="data.length === 0">
        <td :colspan="colspan" class="text-center">
          {{ $t('commons.noData') }}
        </td>
      </tr>

      <template v-for="(rowData, rowIndex) in data" v-else>
        <tr
          v-if="!rowData.colspan"
          :key="rowData.id || rowIndex"
          :class="{
            ['no-hover']: rowData.hover === false || rowData.toValidate,
            ['to-validate']: rowData.toValidate,
            ['disabled-text-color']: rowData.disabledTextColor,
          }"
          :style="rowData.toValidate ? backgroundImage : ''"
          @click="openFilter === null && hover && rowData.hover !== false && handleRowClick(rowIndex)"
        >
          <td
            v-if="rowSelection"
            class="cell-rowSelection"
            :class="getAdditionalCellClasses(rowIndex, 'rowSelection')"
            :style="getAdditionalColumnStickyBounds('rowSelection')"
            @click.stop="!rowData.selectionDisabled && handleCheckedRowChange(rowIndex)"
          >
            <div @click.stop>
              <el-checkbox
                :disabled="rowData.selectionDisabled"
                :value="selection && selection.includes(rowIndex)"
                @change="handleCheckedRowChange(rowIndex)"
              />
            </div>
          </td>
          <td
            v-if="expandable"
            class="cell-expandable"
            :class="getAdditionalCellClasses(rowIndex, 'expandable')"
            :style="getAdditionalColumnStickyBounds('expandable')"
          >
            <div v-if="rowData.expandable" class="d-flex">
              <div class="expandable-icon border rounded d-inline-flex m-auto" @click.stop="setExpanded(rowIndex)">
                <PlusIcon v-if="!expandedRows[rowIndex]" width="18px" height="18px" />
                <MinusIcon v-else width="18px" height="18px" />
              </div>
            </div>
          </td>
          <td
            v-if="showIndex"
            class="cell-index"
            :class="getAdditionalCellClasses(rowIndex, 'index')"
            :style="getAdditionalColumnStickyBounds('index')"
          >
            <!-- @slot Override default table index cell -->
            <slot name="cell-index" v-bind="{ rowIndex }">
              {{ rowIndex + showIndex }}
            </slot>
          </td>

          <td
            v-for="({ key }, columnIndex) in columns"
            :key="`${rowData.id || rowIndex}-${key}`"
            :class="getCellClasses(rowIndex, columnIndex)"
            :style="getStickyBounds(columnIndex)"
          >
            <!-- @slot Override default table data cell -->
            <slot :name="`cell-${key}`" v-bind="{ rowIndex, rowData }">
              <TruncatedText>
                {{ rowData[key] }}
              </TruncatedText>
            </slot>
          </td>
        </tr>
        <tr
          v-if="(data[rowIndex].expandable || data[rowIndex].expandable === undefined) && expandedRows[rowIndex]"
          :key="`${rowData.id || rowIndex}-expandable`"
          class="expandable-row"
        >
          <td :colspan="colspan" :class="rowData.expandableCustomClass" class="position-relative">
            <!-- @slot Implement expandable element to be shown -->
            <slot :ref="`expended-${rowIndex}`" name="expandable-content" v-bind="{ rowIndex, rowData }" />
            <div class="expandable-shadow position-absolute w-100" />
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
/*
Slots API
  Filter - add filter-{column key} template to show filter icon on the column.
  Rendering a popover that can contain any child elements you pass in the slot.
  Triggers by click on the filter icon.
  Closes automatically on mouse wheel event, outer click, or Actions icon click.

  Actions - add actions-{column key} template to show kebab icon on the column.
  Rendering a dropdown container that need a dropdown menu as child component, (currently the API is el-dropdown-menu)
  Triggers by click on the kebab icon.
  Closes automatically on mouse wheel event
  Events - "header-action" with command name and column index.
*/
import { isNil, equals } from 'ramda';
import { onBeforeUnmount } from 'vue';

import { ArrowUpIcon, ArrowDownIcon, FilterIcon, PlusIcon, MinusIcon, KebabIcon } from '@/assets/icons';

import Button from './Button';
import TruncatedText from './TruncatedText';

const additionalColumnsObjects = {
  rowSelection: { name: 'rowSelection', width: 30 },
  expandable: { name: 'expandable', width: 30 },
  index: { name: 'index', width: 55 },
  canDragRows: { name: 'canDragRows', width: 55 },
};

const validateFilteredColumns = (columns, slots) => {
  const filterRegex = new RegExp(/^filter-/);
  const filterColumns = Object.keys(slots)
    .filter((slot) => filterRegex.test(slot))
    .map((slot) => slot.split('filter-').pop());
  filterColumns.forEach((columnKey) => {
    const matchingColumn = columns.find((column) => column.key === columnKey);
    if (typeof matchingColumn.filterActive !== 'boolean')
      throw new Error('Need to add filterActive field on columns when using filters!');
  });
};

const timeoutIds = [];

function debounce(func, timeout = 30) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
    timeoutIds.push(timer);
  };
}

export default {
  components: {
    ArrowUpIcon,
    ArrowDownIcon,
    TruncatedText,
    FilterIcon,
    Button,
    PlusIcon,
    MinusIcon,
    KebabIcon,
  },
  props: {
    small: { type: Boolean, default: false },
    /*
    disabled
      Set the table to be disabled
    */
    disabled: { type: Boolean, default: false },
    /*
    activeSort
      Set default column as sorted -
      columnKey: the key of the sorted column.
      direction: number indicator for the arrow, 1 (up/ascending) or -1 (down/descending)
    */
    activeSort: {
      type: Object,
      default: () => ({
        columnKey: null,
        direction: -1,
      }),
    },
    /*
    expandable
      Adding additional column at the start of the table.
      Gives plus icons to trigger row expand.
    */
    expandable: { type: [Boolean, Object], default: false },
    /*
    rowClickExpandToggle
      Toggles the row expand.
    */
    rowClickExpandToggle: { type: Boolean, default: false },
    /*
    rowSelection
      Adding additional column at the start of the table.
      Adds a checkbox that triggers the row-selection event.
      Can be initialized to set checkboxes to be checked.
    */
    rowSelection: { type: Array, default: null },
    /*
    rowClickSelectionToggle
      Toggles the row as selected or not.
    */
    rowClickSelectionToggle: { type: Boolean, default: false },
    /*
    selectAllToggle
      Show toggle for selecting/unselecting all rows.
    */
    selectAllToggle: { type: Boolean, default: true },
    /*
    rounded
      Rounds the table corners
    */
    rounded: { type: Boolean, default: false },
    /*
    border
      Adding border to the table.
    */
    border: { type: Boolean, default: false },
    /*
    ShowIndex
      Adding additional column at the start of the table.
      Gives index for each row.
    */
    showIndex: { type: [Boolean, Number], default: false },

    /*
    Data
      Array of any object, the value retrieves by the given key property in the columns array.
    */
    data: {
      type: Array,
      default: () => [],
    },

    /*
    columns
      Must contain header and key properties.
      header is the label of the headers.
      Key is the named property to use in the data item, to retrieve the value.

      optionals:
      minWidth - min width of column, cant be mixed with width.
      width - making the table fixed layout, needed for StickyColumns or just for fixed table.
      customClass - add to all specific column class and to its all td cells.
      hidden - (boolean , default: false) hide the column
    */
    columns: {
      type: Array,
      validator: (columnsInput) => columnsInput.every((column) => !!column.key),
      required: true,
    },

    /*
    Hover
      Highlights the row on hover
    */
    hover: { type: Boolean, default: true },

    /*
    StickyColumns
      The number indicated how many columns (starting from the first column) to stick to the start of the table
      needs overflow x to work, therefore if used, all columns must have width in px.
    */
    stickyColumns: { type: [Number, String], validator: (value) => Number.isSafeInteger(Number(value)), default: 0 },

    /*
    CustomClass
      adds string classes to the table element
    */
    customClass: { type: String, default: null },

    /*
    CellClass
      Adds classes to cell elements (td under tbody). Can be either a String or Function.
      Function signature (rowIndex: Number, columnIndex: Number) => String.
    */
    cellClass: { type: [String, Function], default: null },

    /*
    SubHeader
      Sub header functionality, adds sub header to the whole table, specify templates with sub-header-{column.key} to place something in the sub header
      Adds borders to columns with sub header by default
    */
    subHeader: { type: Boolean, default: false },
  },
  setup(props, { slots }) {
    validateFilteredColumns(props.columns, slots);
    onBeforeUnmount(() => timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId)));
  },
  data() {
    const selection = this.rowSelection ?? [];
    const expandedRows = typeof this.expandable === 'object' ? this.expandable : {};

    return {
      sortState: this.activeSort,
      isScrollTop: true,
      isScrollLeft: true,
      openFilter: null,
      expandedRows,
      selection,
      activeActions: null,
      backgroundImage: { 'background-image': `url(${require('@/assets/images/diagonal-striped-pattern.png')})` },
    };
  },
  computed: {
    hasWidth() {
      return this.columns.some((column) => column.width);
    },
    colspan() {
      return this.columns.length + this.additionalColumns.length;
    },
    additionalColumns() {
      const columns = [];
      if (this.rowSelection) columns.push(additionalColumnsObjects.rowSelection);
      if (this.expandable) columns.push(additionalColumnsObjects.expandable);
      if (this.showIndex) columns.push(additionalColumnsObjects.index);
      return columns;
    },
    checkAll() {
      return Boolean(this.data.length) && this.selection.length === this.data.length;
    },
    isIndeterminate() {
      return !this.checkAll && Boolean(this.selection.length);
    },
    isAllCheckboxesDisabled() {
      return this.data.every(({ selectionDisabled }) => selectionDisabled);
    },
  },
  watch: {
    data() {
      if (typeof this.expandable === 'boolean') this.expandedRows = {};
    },
    selection(newSelection, oldSelection) {
      if (!equals(newSelection, oldSelection)) this.$emit('update:row-selection', newSelection);
    },
    rowSelection(newSelection) {
      this.selection = newSelection ?? [];
    },
    expandedRows(value) {
      if (typeof this.expandable === 'object') this.$emit('update:expandable', value);
    },
    expandable(value) {
      if (typeof this.expandable === 'object') this.expandedRows = value;
    },
  },
  mounted() {
    this.$refs.tableContainer?.addEventListener('scroll', this.setScroll, { passive: true });
    this.$refs.tableContainer?.addEventListener('mousewheel', this.handleWheelEvent, { passive: true });
  },
  beforeDestroy() {
    this.$refs.tableContainer?.removeEventListener('scroll', this.setScroll);
    this.$refs.tableContainer?.removeEventListener('mousewheel', this.handleWheelEvent);
  },
  methods: {
    shouldAddSelectBackground(rowIndex) {
      return this.rowSelection && this.selection.some((idx) => idx === rowIndex);
    },

    setScroll(e) {
      debounce(() => {
        const newIsScrollTop = e.target.scrollTop === 0;
        if (newIsScrollTop !== this.isScrollTop) this.isScrollTop = newIsScrollTop;

        const newIsScrollLeft = e.target.scrollLeft === 0;
        if (newIsScrollLeft !== this.isScrollLeft) this.isScrollLeft = newIsScrollLeft;
      })();
    },

    handleRowClick(rowIndex) {
      this.$emit('row-click', rowIndex);
      if (this.rowClickSelectionToggle && this.rowSelection && !this.data[rowIndex].selectionDisabled)
        this.handleCheckedRowChange(rowIndex);
      if (this.rowClickExpandToggle && this.expandedRows) this.setExpanded(rowIndex);
    },
    handleWheelEvent() {
      if (typeof this.openFilter === 'number') {
        this.openFilter = null;
      }
      if (typeof this.activeActions === 'number') {
        document.getElementById(`actions-header-${this.activeActions}`).click();
        this.activeActions = null;
      }
    },
    handleSortClick(column) {
      if (this.sortState.columnKey != column.key) {
        this.$set(this.sortState, 'columnKey', column.key);
      } else {
        this.$set(this.sortState, 'direction', this.sortState.direction * -1);
      }
      column.sortCallback(this.sortState.direction);
    },

    getCellClasses(rowIndex, columnIndex) {
      const classes = [];
      if (this.activeActions === columnIndex) classes.push('active-actions');
      if (this.isSticky(columnIndex)) {
        classes.push('sticky-column');
      }
      const { customClass, hidden } = this.columns[columnIndex];
      if (hidden) classes.push('hidden-column');
      if (customClass) {
        classes.push(customClass);
      }
      if (this.shouldAddSelectBackground(rowIndex)) classes.push('bg-selected');

      const cellClass =
        typeof this.cellClass === 'function'
          ? this.cellClass(rowIndex, columnIndex + this.additionalColumns.length)
          : this.cellClass;
      if (cellClass) {
        classes.push(cellClass);
      }
      return classes.join(' ');
    },

    getAdditionalCellClasses(rowIndex, keyName) {
      const classes = [];
      if (this.hasWidth && this.stickyColumns) {
        classes.push('sticky-column');
      }
      if (this.shouldAddSelectBackground(rowIndex)) classes.push('bg-selected');

      const cellClass =
        typeof this.cellClass === 'function'
          ? this.cellClass(rowIndex, this.getAdditionalColumnIndex(keyName))
          : this.cellClass;
      if (cellClass) {
        classes.push(cellClass);
      }

      return classes.join(' ');
    },
    getHeaderStyle(columnIndex) {
      const column = this.columns[columnIndex];

      return {
        width: column.width,
        minWidth: column.minWidth,
        ...this.getStickyBounds(columnIndex),
      };
    },
    getStickyBounds(index) {
      if (!this.isSticky(index)) return null;
      const indexIncludingAdditionalColumns = index + this.additionalColumns.length;

      //NOTE: important to keep the additional columns first
      const columnsIncludingAdditionalColumns = this.additionalColumns.concat(this.columns);
      return this.calculateBounds(indexIncludingAdditionalColumns, columnsIncludingAdditionalColumns);
    },

    getAdditionalColumnStickyBounds(keyName) {
      if (this.hasWidth && this.stickyColumns)
        return this.calculateBounds(this.getAdditionalColumnIndex(keyName), this.additionalColumns);
    },

    getAdditionalColumnIndex(keyName) {
      return this.additionalColumns.findIndex(({ name }) => name === keyName);
    },

    calculateBounds(index, columns) {
      const columnsWidthToSum = columns.map(({ width }) => Number(width.toString().replace('px', ''))).splice(0, index);
      const totalWidth = columnsWidthToSum.reduce((acc, value) => acc + value, 0);
      const direction = this.$t('direction');

      if (direction === 'rtl') return { right: `${totalWidth}px` };
      return { left: `${totalWidth}px` };
    },

    isSticky(index) {
      return this.hasWidth && index < this.stickyColumns;
    },

    setExpanded(rowIndex) {
      this.$set(this.expandedRows, rowIndex, !this.expandedRows[rowIndex]);
    },

    handleCheckAllChange(checked) {
      this.selection = checked
        ? this.data.map(({ selectionDisabled }, idx) => (!selectionDisabled ? idx : null)).filter((idx) => !isNil(idx))
        : [];
      this.$emit('on-selection-check-all', checked);
    },
    handleCheckedRowChange(rowIndex) {
      let newSelection = [...this.selection];
      const selectionIndex = newSelection.indexOf(rowIndex);
      let selected = true;
      if (rowIndex === newSelection[selectionIndex]) {
        newSelection.splice(selectionIndex, 1);
        selected = false;
      } else {
        newSelection.push(rowIndex);
      }
      this.$emit('on-select-row', { rowIndex, selected });
      this.selection = newSelection;
    },
    actionsVisibleChange(isVisible, columnIndex) {
      this.activeActions = isVisible ? columnIndex : null;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

table > thead,
table > tbody {
  word-break: normal;
}

table > thead > tr > th {
  vertical-align: top;
  position: sticky;
  top: 0;

  div.scrollingY > & {
    box-shadow: 0px 4px 5px -2px rgba(91, 104, 118, 0.09);
  }

  &.sortable-header {
    &:hover {
      .sort-icon {
        visibility: visible;
      }
    }

    .sort-icon {
      visibility: hidden;

      &.active-sort {
        visibility: visible;
      }
    }
  }

  &.actionable-header {
    padding-top: 0;

    .header-text {
      padding-top: $table-padding-y;
    }

    .icon-btn {
      margin-top: $table-padding-y - $button-icon-padding;
    }

    &:hover {
      background-color: $light-gray;
    }
  }
}

table > thead > tr > th,
table > tbody > tr > td {
  background: $white;
  &.hidden-column {
    display: none;
  }
  &.sticky-column {
    position: sticky;
    &:not(td) {
      z-index: 1;
    }
    div.scrollingX > & {
      [dir='rtl'] & {
        box-shadow: -4px 0px 5px rgba(91, 104, 118, 0.09);
      }
      [dir='ltr'] & {
        box-shadow: 4px 0px 5px rgba(91, 104, 118, 0.09);
      }
    }
  }

  &.active-actions {
    background: $surfaces-selected;
  }
}

.no-border-top {
  border-top: 0px !important;
}

.table-hover tr:not(.expandable-row):not(.no-hover):hover td {
  cursor: pointer;
}

.table-hover tr.no-hover:hover td {
  --bs-table-hover-bg: none;
}

td.cell-index,
th.header-index {
  [dir='ltr'] & {
    padding-right: 5px;
  }
  [dir='rtl'] & {
    padding-left: 5px;
  }
}

td.cell-rowSelection,
th.header-rowSelection {
  [dir='ltr'] & {
    padding-right: 0px;
  }
  [dir='rtl'] & {
    padding-left: 0px;
  }
}

td.cell-expandable,
th.header-expandable {
  [dir='ltr'] & {
    padding-right: 0px;
    padding-left: 10px;
  }
  [dir='rtl'] & {
    padding-left: 0px;
    padding-right: 10px;
  }
}

table > tbody > tr > td {
  vertical-align: middle;

  .expandable-icon {
    &:hover {
      cursor: pointer;
      background: $secondary;
    }
  }
  .expandable-shadow {
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.14) 0%, rgba(0, 0, 0, 0) 100%);
    height: 0.5rem;
  }
}

table.fixed-layout {
  table-layout: fixed;
}

[dir='ltr'] table.rounded-table {
  border-spacing: 0px;

  > thead:first-child > tr:first-child > th:first-child,
  > tbody:first-child > tr:first-child > td:first-child {
    border-radius: 6px 0 0 0;
  }
  > thead:first-child > tr:first-child > th:last-child,
  > tbody:first-child > tr:first-child > td:last-child {
    border-radius: 0 6px 0 0;
  }
  > thead:last-child > tr:last-child > th:first-child,
  > tbody:last-child > tr:last-child > td:first-child {
    border-radius: 0 0 0 6px;
  }
  > thead:last-child > tr:last-child > th:last-child,
  > tbody:last-child > tr:last-child > td:last-child {
    border-radius: 0 0 6px 0;
  }
}

tr:last-child > td {
  border-bottom-width: 0px;
}

table tbody tr.to-validate td {
  background: transparent;
}

table tbody tr.disabled-text-color td {
  color: #9295a5;
}

::v-deep .disabled {
  color: $typography-disabled !important;
}
</style>
