var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link px-0 py-4 border-top-0 border-end-0 border-start-0 border-bottom fw-bold d-flex gap-1 align-items-center text-decoration-none border-4",class:{
    active: _vm.active,
    disabled: _vm.disabled,
    ['hidden-tab']: _vm.hidden,
    ['text-typography-secondary']: !_vm.active,
    [' border-primary']: _vm.active,
  },attrs:{"href":""},on:{"click":function($event){$event.preventDefault();}}},[_vm._t("prefixIcon"),_c('p',{class:{ ['fw-bold']: _vm.active }},[_vm._v(_vm._s(_vm.text))]),(_vm.badgeValue !== null)?_c('span',{staticClass:"badge",class:{ ['bg-primary']: _vm.active, ['bg-secondary text-typography-secondary']: !_vm.active }},[_vm._v(" "+_vm._s(_vm.badgeValue)+" ")]):_vm._e(),_vm._t("suffixIcon")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }