<template>
  <a
    href=""
    class="nav-link px-0 py-4 border-top-0 border-end-0 border-start-0 border-bottom fw-bold d-flex gap-1 align-items-center text-decoration-none border-4"
    :class="{
      active,
      disabled,
      ['hidden-tab']: hidden,
      ['text-typography-secondary']: !active,
      [' border-primary']: active,
    }"
    @click.prevent
  >
    <slot name="prefixIcon" />
    <p :class="{ ['fw-bold']: active }">{{ text }}</p>
    <span
      v-if="badgeValue !== null"
      class="badge"
      :class="{ ['bg-primary']: active, ['bg-secondary text-typography-secondary']: !active }"
    >
      {{ badgeValue }}
    </span>
    <slot name="suffixIcon" />
  </a>
</template>

<script>
export default {
  props: {
    text: { type: String, default: '' },
    badgeValue: { type: Number, default: null },
    active: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    hidden: { type: Boolean, default: false },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

a {
  &.hidden-tab {
    display: none !important;
  }
  &.text-typography-secondary {
    border-color: transparent !important;
  }
  &:not(.active):hover {
    color: $typography-primary !important;
    .badge {
      color: $typography-primary !important;
    }
  }
}
</style>
